import { Link } from "react-router-dom";
import { ArrowIconLength } from "../lib/props";
import { VarColor, VarColorVal } from "../lib/vars";
import LogoIcon from "./logoIcon";
import MenuIcon from "./menuIcon";
import PillButton from "./pillButton";
import { Suspense, lazy } from "react";

const HeaderMenu = lazy(() => import("./headerMenu"));

const Navbar = ({ scrollRef, bgColor, menuActive, setMenuActive }) => {
  return (
    <>
      <div id="navbar" className="flex fixed justify-between w-full pl-5 pr-6 top-3 md:pt-5 md:px-20 z-[911]">
        {menuActive && (
          <PillButton
            textColor={VarColor.primary}
            strokeColor={VarColorVal.white}
            arrowLength={ArrowIconLength.small}
            arrowSize={16}
            className="w-44 md:hidden"
          >
            REACH OUT
          </PillButton>
        )}
        <Link to="/">
          <LogoIcon
            stroke={bgColor === VarColorVal.white || menuActive ? VarColorVal.primary : VarColorVal.white}
            className={`${menuActive && "hidden"} w-12 h-12 transition animate-fade-in-left md:block cursor-pointer`}
          />
        </Link>
        <div className="flex animate-fade-in-bottom">
          <Link to="/our-work">
            <PillButton
              textColor={bgColor === VarColorVal.primary ? VarColor.white : VarColor.primary}
              strokeColor={bgColor === VarColorVal.primary ? VarColorVal.white : VarColorVal.primary}
              arrowLength={ArrowIconLength.small}
              arrowSize={16}
              className={`${
                bgColor === VarColorVal.white ? "bg-white " : "bg-primary "
              } hidden w-52 mr-6 cursor-pointer md:flex `}
            >
              OUR WORK
            </PillButton>
          </Link>
          <div
            className={`
          ${
            bgColor === VarColorVal.white ? "bg-primary " : "bg-white "
          } flex items-center justify-center h-11 w-[58px] rounded-full transition cursor-pointer`}
            onClick={() => {
              setMenuActive(!menuActive);
            }}
          >
            <MenuIcon
              stroke={bgColor === VarColorVal.white ? VarColorVal.white : VarColorVal.primary}
              className="transition"
              active={menuActive}
            />
          </div>
        </div>
      </div>

      <Suspense>
        <HeaderMenu scrollRef={scrollRef} menuActive={menuActive} setMenuActive={setMenuActive} />
      </Suspense>
    </>
  );
};

export default Navbar;
