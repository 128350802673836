import { VarColor, VarColorVal } from "./vars";
import { ReactNode } from "react";

interface Prop {
  className?: string;
  id?: string;
}

export interface IconProps extends Prop {
  stroke: VarColorVal;
}

export enum ArrowIconLength {
  xs,
  small,
  large,
}

export interface ArrowIconProps extends IconProps {
  arrowLength: ArrowIconLength;
  size: number;
}

export interface PillButtonProps extends Prop {
  textColor: VarColor;
  strokeColor: VarColorVal;
  arrowLength: ArrowIconLength;
  arrowSize: number;
  children: string;
  to?: string;
  bgColor?: VarColorVal;
  onClick?: () => void;
}

export interface PillTextProps extends Prop {
  children: ReactNode;
}

export interface ScaleBoxProps extends Prop {
  children: ReactNode;
  trigger: string;
}
