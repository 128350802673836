export interface NavLink {
  text: string;
  link?: string;
  element?: string;
}

export const navlinks: NavLink[] = [
  {
    text: "ABOUT US",
    link: "/",
    element: "#hero2Title",
  },
  {
    text: "SERVICES",
    link: "/",
    element: "#servicesTarget",
  },
  {
    text: "OUR WORK",
    link: "/our-work",
  },
  {
    text: "CLIENTS",
    link: "/",
    element: "#tpTitle",
  },
];

export interface Service {
  icon: string;
  heading: string;
  paragraph: string;
}

export const services: Service[] = [
  {
    icon: "branding",
    heading: "Branding/Promotion",
    paragraph:
      "Building a strong, recognizable brand is essential in today's digital world. Our team at ODS specializes in crafting impactful branding and promotional strategies that resonate with your target audience, enhancing brand loyalty and driving long-term engagement.",
  },
  {
    icon: "camera",
    heading: "Paid Social",
    paragraph:
      "By combining our deep understanding of a brand's target audience with the right messaging, on the right platform, and at the right time of day, we can create a truly engaging experience for potential customers.",
  },
  {
    icon: "web",
    heading: "Web & App Development",
    paragraph:
      "At ODS, we don't just build websites; we sculpt immersive digital journeys. Pushing the boundaries of design and interactivity, our creations are more than just websites—they're groundbreaking experiences that redefine and evolve the digital landscape.",
  },
  {
    icon: "play",
    heading: "Content Creation & Strategy",
    paragraph:
      "With our content creation services, we help attract the right people to your business and engage them with information to build trust, address key challenges, and aid their buying decisions.",
  },
  {
    icon: "chart",
    heading: "Lead Generation",
    paragraph:
      "Our lead generation services are designed to help you identify and target the right audience to maximize your chances of success. We use a range of tactics to generate leads that are most likely to convert.",
  },
  {
    icon: "click",
    heading: "Google Ads",
    paragraph:
      "Our team of Google Ads experts uses advanced targeting techniques to reach your ideal audience, and we constantly monitor and optimize your campaigns to ensure you get the most out of your budget.",
  },
  {
    icon: "download",
    heading: "Apple Search Ads",
    paragraph:
      "At ODS, We use advanced targeting techniques to reach your ideal audience and constantly monitor and optimize your campaigns to ensure you get the most return on your investment.",
  },
];
