import { lazy, useEffect, useRef, useState } from "react";
import LogoIcon from "../components/logoIcon";
import PillButton from "../components/pillButton";
import { VarColor, VarColorVal } from "../lib/vars";
import { ArrowIconLength } from "../lib/props";
import { Service, services } from "../lib/content";
import ScrollTrigger from "gsap/ScrollTrigger";
import gsap from "gsap";
import LocomotiveScroll from "locomotive-scroll";
import locomotiveScroll from "locomotive-scroll";
import { Canvas, useLoader } from "@react-three/fiber";
import { ACESFilmicToneMapping } from "three";
import { RGBELoader } from "three-stdlib";
import * as THREE from "three";
import { Environment } from "@react-three/drei";
import Navbar from "../components/navbar";
import { HorizontalTicker } from "react-infinite-ticker";
import clsx from "clsx";
import { useNavContext } from "../context/navContext";
import { sanityClient, urlFor } from "../lib/clients/sanity.client";
import { useQuery } from "@tanstack/react-query";
import ArrowIcon from "../components/arrowIcon";

const FatStar = lazy(() => import("../components/Models/FatStar"));
const Arrow = lazy(() => import("../components/Models/arrow"));
const SlimStar = lazy(() => import("../components/Models/SlimStar"));
const PillText = lazy(() => import("../components/pillText"));

gsap.registerPlugin(ScrollTrigger);

interface ComapanyLogo {
  name: string;
  url: string;
  logo: {
    asset: {
      _ref: string;
    };
  };
}

function Home() {
  const { scrollToId, setScrollToId } = useNavContext();
  const [bgColor, setBgColor] = useState(VarColorVal.white);
  const [menuActive, setMenuActive] = useState(false);

  const [bubbleWidth, setBubbleWidth] = useState(0);

  const [pill1Width, setPill1Width] = useState(0);
  const [pill2Width, setPill2Width] = useState(0);
  const [pill3Width, setPill3Width] = useState(0);

  const pill1Pos = useRef(0);
  const pill2Pos = useRef(0);
  const pill3Pos = useRef(0);

  const bubblePos = useRef(0);
  const scrollElRef = useRef(null);
  const scrollRef = useRef<LocomotiveScroll | null>(null);

  const [fatStarRotation, setFatStarRotation] = useState([0.5, 0.3, -0.3]);
  const [slimStarRotation, setSlimStarRotation] = useState([4.51, 21.69, 59.23]);
  const [arrowRotation, setArrowRotation] = useState([1.5, 1, 0.2]);

  const [slimStarPosition, setSlimStarPosition] = useState([0, 0, 0]);
  const [arrowPosition, setArrowPosition] = useState([-1, 0, 0]);

  useEffect(() => {
    if (scrollToId?.length > 0) {
      scrollRef.current.scrollTo(scrollToId);
      setScrollToId("");
    }

    if (scrollElRef.current) {
      scrollRef.current?.destroy();

      scrollRef.current = new locomotiveScroll({
        el: scrollElRef.current,
        smooth: true,
        smartphone: {
          smooth: true,
        },
        multiplier: window.screen.width < 768 ? 1.4 : 0.95,
      });

      const handleLocoScroll = (event) => {
        const pagesScrolled = event.scroll.y / window.screen.height;

        const isMobile = window.screen.width < window.screen.height;
        if (!isMobile) {
          if(pagesScrolled <= 1.3) setBgColor(VarColorVal.white);
          else if(pagesScrolled >= 1.3 && pagesScrolled < 1.7) setBgColor(VarColorVal.primary);
          else if (pagesScrolled >= 1.7 && pagesScrolled < 3.4) setBgColor(VarColorVal.white);
          else if (pagesScrolled >= 3.4 && pagesScrolled < 4.8) setBgColor(VarColorVal.primary); 
          else if (pagesScrolled >= 9.8) setBgColor(VarColorVal.primary);
          else setBgColor(VarColorVal.white);
        } else {
          if(pagesScrolled <= 1.6) setBgColor(VarColorVal.white);
          else if(pagesScrolled >= 1.6 && pagesScrolled < 2.35) setBgColor(VarColorVal.primary);
          else setBgColor(VarColorVal.white);
        }
       
        ScrollTrigger.update(); 

        setSlimStarRotation([4.51 - event.scroll.y / 1000, 21.69, 59.23 + event.scroll.y / 100]);

        setFatStarRotation([0.1, 2 + event.scroll.y / 1000, -0.1]);

        setArrowRotation([1.5, 1 + (event.scroll.y - 2500) / 500, 0.2 - event.scroll.y / 100]);

        setSlimStarPosition([
          window.screen.width < 768 ? -8 : -35,
          (window.screen.width < 768 ? 27 : 0) + event.scroll.y / 200 - 12,
          0,
        ]);
        setArrowPosition([-1, 0 + event.scroll.y / 200 - 16, 0]);

        if (Object.keys(event.currentElements).includes("bubble")) {
          if (bubblePos.current === 0) bubblePos.current = event.scroll.y;
          setBubbleWidth(
            Math.min(Math.max(event.scroll.y, 0) - bubblePos.current, window.screen.width < 768 ? 2000 : 500) /
              (window.screen.width < 768 ? 7 : 5)
          );
        }

        const pill1Text = document.getElementById("pill1-text");
        const pill2Text = document.getElementById("pill2-text");
        const pill3Text = document.getElementById("pill3-text");

        if (Object.keys(event.currentElements).includes("pill1")) {
          if (pill1Pos.current === 0) pill1Pos.current = event.scroll.y + (window.screen.width > 768 ? 50 : 0);
          setPill1Width(Math.min(Math.max(event.scroll.y, 0) - pill1Pos.current, pill1Text.offsetWidth + 10));
        }
        if (Object.keys(event.currentElements).includes("pill2")) {
          if (pill2Pos.current === 0) pill2Pos.current = event.scroll.y + (window.screen.width > 768 ? 250 : 50);
          setPill2Width(Math.min(Math.max(event.scroll.y, 0) - pill2Pos.current, pill2Text.offsetWidth + 10));
        }
        if (Object.keys(event.currentElements).includes("pill3")) {
          if (pill3Pos.current === 0) pill3Pos.current = event.scroll.y + (window.screen.width > 768 ? 350 : 100);
          setPill3Width(Math.min(Math.max(event.scroll.y, 0) - pill3Pos.current, pill3Text.offsetWidth + 10));
        }
      };

      scrollRef.current.on("scroll", handleLocoScroll);

      ScrollTrigger.scrollerProxy(scrollElRef.current, {
        scrollTop(value) {
          return arguments.length
            ? scrollRef.current.scrollTo(value, {
                duration: 0,
                disableLerp: true,
              })
            : // @ts-ignore
              scrollRef.current.scroll.instance.scroll.y;
        },
        getBoundingClientRect() {
          return {
            top: 0,
            left: 0,
            width: window.innerWidth,
            height: window.innerHeight,
          };
        },
        pinType: scrollElRef.current.style.transform ? "transform" : "fixed",
      });

      ScrollTrigger.addEventListener("refresh", () => scrollRef.current.update());
      ScrollTrigger.defaults({ scroller: scrollElRef.current });
    }

    gsap.to("#hero2Title", {
      opacity: 1,
      ease: "power3.inOut",
      scrollTrigger: {
        trigger: "#hero2Title",
        scrub: true,
        end: "bottom 50%",
      },
    });

    window.screen.width > 768 &&
      gsap.to("#pms-underline", {
        width: "100%",
        ease: "power3.inOut",
        scrollTrigger: {
          trigger: "#pms-underline",
          scrub: true,
          end: "bottom 40%",
        },
      });

    gsap.to("#hero2Title", {
      x: 15,
      ease: "power3.inOut",
      scrollTrigger: {
        trigger: "#hero2Title",
        scrub: true,
        end: "bottom 25%",
      },
    });

    gsap.to("#hero2Body", {
      opacity: 1,
      ease: "power3.inOut",
      scrollTrigger: {
        trigger: "#hero2Body",
        scrub: true,
        end: "bottom 50%",
      },
    });

    gsap.to("#hero2Body", {
      x: 15,
      ease: "power3.inOut",
      scrollTrigger: {
        trigger: "#hero2Body",
        scrub: true,
        end: "bottom 15%",
      },
    });

    gsap.to("#weThinkTitle", {
      opacity: 1,
      ease: "power3.inOut",
      scrollTrigger: {
        trigger: "#weThinkTitle",
        scrub: true,
        end: "bottom 50%",
      },
    });

    gsap.to("#weThinkTitle", {
      x: 25,
      ease: "power3.inOut",
      scrollTrigger: {
        trigger: "#weThinkTitle",
        scrub: true,
        end: "bottom 25%",
      },
    });

    gsap.to("#weThinkTitle2", {
      opacity: 1,
      ease: "power3.inOut",
      scrollTrigger: {
        trigger: "#weThinkTitle2",
        scrub: true,
        end: "bottom 50%",
      },
    });

    gsap.to("#weThinkTitle2", {
      x: 25,
      ease: "power3.inOut",
      scrollTrigger: {
        trigger: "#weThinkTitle2",
        scrub: true,
        end: "bottom 25%",
      },
    });

    gsap.to("#weThinkBody1", {
      opacity: 1,
      ease: "power3.inOut",
      scrollTrigger: {
        trigger: "#weThinkBody1",
        scrub: true,
        end: "bottom 50%",
      },
    });

    gsap.to("#weThinkBody1", {
      y: -15,
      ease: "power3.inOut",
      scrollTrigger: {
        trigger: "#weThinkBody1",
        scrub: true,
        end: "bottom 75%",
      },
    });

    gsap.to("#weThinkBody2", {
      opacity: 1,
      ease: "power3.inOut",
      scrollTrigger: {
        trigger: "#weThinkBody2",
        scrub: true,
        end: "bottom 50%",
      },
    });

    gsap.to("#weThinkBody2", {
      y: -15,
      ease: "power3.inOut",
      scrollTrigger: {
        trigger: "#weThinkBody1",
        scrub: true,
        end: "bottom 75%",
      },
    });

    gsap.to("#weThink", {
      scaleX: 1,
      y: -100,
      ease: "power3.inOut",
      scrollTrigger: {
        trigger: "#weThink",
        scrub: true,
        end: "bottom 90%",
      },
    });

    gsap.fromTo(
      "#defyOdds",
      {
        left: "0vw",
      },
      {
        left: "-150vw",
        scrollTrigger: {
          trigger: "#hero3",
          end: "bottom+=300vh bottom",
          scrub: true,
        },
      }
    );

    gsap.to("#hero3Title", {
      opacity: 1,
      ease: "power3.inOut",
      scrollTrigger: {
        trigger: "#hero3Title",
        scrub: true,
        end: "bottom 50%",
      },
    });

    gsap.to("#hero3Title", {
      x: 15,
      ease: "power3.inOut",
      scrollTrigger: {
        trigger: "#hero3Title",
        scrub: true,
        end: "bottom 25%",
      },
    });

    gsap.to("#hero3Body", {
      opacity: 1,
      ease: "power3.inOut",
      scrollTrigger: {
        trigger: "#hero3Body",
        scrub: true,
        end: "bottom 50%",
      },
    });

    gsap.to("#hero3Body", {
      x: 15,
      ease: "power3.inOut",
      scrollTrigger: {
        trigger: "#hero3Body",
        scrub: true,
        end: "bottom 25%",
      },
    });

    gsap.to("#hero3Body2", {
      x: -15,
      opacity: 1,
      ease: "power3.inOut",
      scrollTrigger: {
        trigger: "#hero3Body2",
        scrub: true,
        end: "bottom 15%",
      },
    });

    gsap.to("#ourPromise", {
      opacity: 1,
      ease: "power3.inOut",
      scrollTrigger: {
        trigger: "#ourPromise",
        scrub: true,
        end: "bottom 50%",
      },
    });

    gsap.to("#ourPromise", {
      x: 15,
      ease: "power3.inOut",
      scrollTrigger: {
        trigger: "#ourPromise",
        scrub: true,
        end: "bottom 25%",
      },
    });

    gsap.to("#hero4", {
      y: -100,
      ease: "power3.inOut",
      scrollTrigger: {
        trigger: "#hero4",
        scrub: true,
        end: "top 50%",
      },
    });

    gsap.to("#hero4", {
      scaleX: 1,
      ease: "power3.inOut",
      scrollTrigger: {
        trigger: "#hero4",
        scrub: true,
        end: "top 60%",
      },
    });


    if(window.screen.width > window.screen.height) gsap.to("#hero4Title", {
      yPercent: 110,
      scrollTrigger: {
        trigger: "#hero4",
        scrub: true,
        start: "top top",
        endTrigger: "#servicesTarget",
      },
    });

    gsap.utils.toArray(".hero4Bullet").forEach((el: any) => {
      gsap.to(el, {
        opacity: 1,
        scrollTrigger: {
          trigger: el,
          scrub: true,
          end: "bottom 50%",
        },
      });
    });

    gsap.to("#hero5Img", {
      opacity: 1,
      ease: "power3.inOut",
      scrollTrigger: {
        trigger: "#hero5Img",
        scrub: true,
        end: "bottom 50%",
      },
    });

    gsap.to("#hero5Img", {
      y: -15,
      ease: "power3.inOut",
      scrollTrigger: {
        trigger: "#hero5Img",
        scrub: true,
        end: "bottom 25%",
      },
    });

    gsap.to("#hero5Title", {
      opacity: 1,
      ease: "power3.inOut",
      scrollTrigger: {
        trigger: "#hero5Title",
        scrub: true,
        end: "bottom 50%",
      },
    });

    gsap.to("#hero5Title", {
      y: -15,
      ease: "power3.inOut",
      scrollTrigger: {
        trigger: "#hero5Title",
        scrub: true,
        end: "bottom 50%",
      },
    });

    gsap.to("#hero5Subtitle", {
      opacity: 1,
      ease: "power3.inOut",
      scrollTrigger: {
        trigger: "#hero5Subtitle",
        scrub: true,
        end: "bottom 50%",
      },
    });

    gsap.to("#hero5Subtitle", {
      x: -15,
      ease: "power3.inOut",
      scrollTrigger: {
        trigger: "#hero5Subtitle",
        scrub: true,
        end: "bottom 50%",
      },
    });

    gsap.to("#hero5Body", {
      opacity: 1,
      ease: "power3.inOut",
      scrollTrigger: {
        trigger: "#hero5Body",
        scrub: true,
        end: "bottom 70%",
      },
    });

    gsap.to("#hero5Body", {
      x: -16,
      ease: "power3.inOut",
      scrollTrigger: {
        trigger: "#hero5Body",
        scrub: true,
        end: "bottom 70%",
      },
    });

    gsap.to("#navbar", {
      yPercent: 0,
      scrollTrigger: {
        trigger: "#navbar",
        start: "top top",
        endTrigger: "#servicesTarget",
        scrub: 0.6,
      },
    });

    gsap.to("#tpTitle", {
      opacity: 1,
      ease: "power3.inOut",
      scrollTrigger: {
        trigger: "#tpTitle",
        scrub: true,
        end: "bottom 50%",
      },
    });

    gsap.to("#tpTitle", {
      y: -15,
      ease: "power3.inOut",
      scrollTrigger: {
        trigger: "#tpTitle",
        scrub: true,
        end: "bottom 25%",
      },
    });

    gsap.to("#tpSubtitle", {
      opacity: 1,
      ease: "power3.inOut",
      scrollTrigger: {
        trigger: "#tpSubtitle",
        scrub: true,
        end: "bottom 50%",
      },
    });

    gsap.to("#tpSubtitle", {
      x: 15,
      ease: "power3.inOut",
      scrollTrigger: {
        trigger: "#tpSubtitle",
        scrub: true,
        end: "bottom 25%",
      },
    });

    gsap.to("#tp-underline", {
      width: "100%",
      ease: "power3.inOut",
      scrollTrigger: {
        trigger: "#tp-underline",
        scrub: true,
        end: "bottom 40%",
      },
    });

    gsap.to("#tp-box", {
      opacity: 1,
      ease: "power3.inOut",
      scrollTrigger: {
        trigger: "#tp-box",
        scrub: true,
        end: "bottom 50%",
      },
    });

    gsap.to("#tp-box", {
      x: -15,
      ease: "power3.inOut",
      scrollTrigger: {
        trigger: "#tp-box",
        scrub: true,
        end: "bottom 25%",
      },
    });

    gsap.to("#brands-band", {
      xPercent: -10,
      ease: "linear",
      scrollTrigger: {
        trigger: "#brands-band",
        scrub: true,
        start: "top bottom",
        end: "bottom top",
      },
    });

    return () => {
      scrollRef.current?.destroy();
    };
  }, []);

  const hdrMap = useLoader(
    RGBELoader,
    "https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/1k/peppermint_powerplant_2_1k.hdr"
  );
  hdrMap.mapping = THREE.EquirectangularReflectionMapping;

  const [formOpen, setFormOpen] = useState(false);

  const { data: companyLogos } = useQuery(["allWork"], () => sanityClient.fetch(`*[_type == "companies"]`));

  return (
    <>
      {formOpen && (
        <div
          className={clsx(
            formOpen ? "visible" : " invisible",
            "absolute top-0 left-0 flex opacity-1 items-center justify-center w-screen h-screen z-[900] overflow-hidden transition-all "
          )}
        >
          <div className="w-4/5 h-4/5 bg-primary rounded-2xl px-[28px] z-[910] flex flex-col">
            <h3 className="font-flexible text-white text-4xl leading-[5rem] mb-6 md:text-[100px] md:leading-[10rem]">
              Contact Us
            </h3>

            <div className="w-full flex h-1/2 ">
              <div className="flex flex-col w-1/3 mr-8 gap-y-4">
                <input
                  className="rounded-full px-4 py-2 border text-primary outline-none placeholder-primary border-white bg-white"
                  type="text"
                  placeholder="Full Name"
                />
                <input
                  className="rounded-full px-4 py-2 border text-primary outline-none placeholder-primary border-white bg-white"
                  type="text"
                  placeholder="Email"
                />
              </div>
              <textarea
                placeholder="Message"
                className="w-full rounded-2xl px-4 py-2 border text-primary outline-none placeholder-primary border-white bg-white "
              />
            </div>
            <div className="self-end mt-28">
              <PillButton
                className="w-36 cursor-pointer"
                textColor={VarColor.white}
                strokeColor={VarColorVal.white}
                arrowLength={ArrowIconLength.small}
                arrowSize={16}
              >
                SEND
              </PillButton>
            </div>
          </div>

          <div onClick={() => setFormOpen(false)} className="bg-black opacity-30 w-full h-full absolute z-[901]" />
        </div>
      )}
      {menuActive && (
        <div
          className="absolute bg-black opacity-30 w-full h-full z-[901]"
          onClick={() => {
            setMenuActive(false);
          }}
        />
      )}
      <Navbar scrollRef={scrollRef} bgColor={bgColor} menuActive={menuActive} setMenuActive={setMenuActive} />
      <main
        data-scroll-container
        className="flex flex-col items-start w-screen bg-white overflow-hidden"
        ref={scrollElRef}
      >
        <div className="w-screen md:w-auto">
          {/* Hero 1 */}
          <div
            className="w-full min-h-screen flex justify-center items-center pb-[15vh] relative md:h-screen"
            id="hero"
          >
            <div className=" w-[95vw] sm:w-[48vw] sm:h-screen top-[2.5vh] sm:top-0 absolute left-1/2 -translate-x-1/2  overflow-hidden">
              <img
                src={
                  window.screen.width > window.screen.height
                    ? "assets/backgrounds/hero-bg.png"
                    : "assets/backgrounds/hero-bg-full.png"
                }
                alt="Hero Background"
                className="w-full h-full object-fit object-center"
              />
            </div>
            <div
              className="absolute bottom-0 w-full h-2/5"
              style={{
                background: `linear-gradient(to top, #EEEEEE 55%, transparent 100%)`,
              }}
            />

            {/* Middle */}
            <div className="w-full aspect-square flex flex-col items-center justify-center relative">
              <div className="absolute w-full aspect-square top-0 left-0 z-0 opacity-100 md:w-1/2 md:top-1/2 md:left-1/2 md:-translate-x-1/2 md:-translate-y-1/2"></div>
              <img
                src="assets/images/we_defy_the_odds_primary.svg"
                alt="We defy the odds"
                className="mb-3 z-10 animate-fade-in-bottom animation-delay-3000 md:w-48 md:mb-4 mobile:w-32"
              />
              <div className="text-center text-primary text-[36px] font-medium leading-9 z-10 md:text-8xl">
                <p className="animate-fade-in-left animation-delay-1750">Elevating brands</p>
                <p className="animate-fade-in-left animation-delay-2000">through performance</p>
                <p className="animate-fade-in-left animation-delay-2500">marketing solutions.</p>
              </div>
              <a
                href="/our-work"
                className="hidden mobile:flex items-center justify-between z-10 text-white bg-primary px-6 text-sm py-2 rounded-full mt-6 font-medium animate-fade-in animation-delay-3200"
              >
                OUR WORK
                <ArrowIcon className="ml-4" arrowLength={ArrowIconLength.xs} stroke={VarColorVal.white} size={16} />
              </a>
            </div>
            {/* Bottom */}
            <div className="absolute bottom-0 mx-[1.5vw] px-[1.5vw] pt-3 w-[94vw] h-[175px] border-t-[1px] border-black md:flex md:px-0 justify-between items-center mb-16">
              <div className="md:flex items-center">
                <img
                  src="assets/icons/diamond.svg"
                  alt="diamond icon"
                  className="my-3 w-6 h-6 md:w-9 md:h-9 md:mr-6 animate-fade-in-left animation-delay-3250"
                />
                <p className="text-[0.8rem] font-normal animate-fade-in-left animation-delay-3500">
                  We are a full-service premium digital marketing agency, <br />
                  dedicated to building long-lasting relationships with our clients.
                </p>
              </div>
              <div className="flex justify-between my-8">
                <a
                  href="mailto:business@odsmarketing.com"
                  target="_blank"
                  rel="noreferrer"
                  className="text-sm text-primary md:pr-20 animate-fade-in-bottom animation-delay-4000"
                >
                  BUSINESS@ODSMARKETING.COM
                </a>

                <p className="text-sm animate-fade-in-bottom animation-delay-4250">EST. 2020</p>
              </div>
            </div>
          </div>
          {/* Company Logos */}
          {companyLogos && (
            <div className="overflow-hidden w-screen">
              <div className="min-h-[100px] w-[120vw]" id="brands-band">
                <HorizontalTicker delay={1} duration={40000}>
                  {companyLogos?.map((logo: ComapanyLogo, key: number) => (
                    <a className="mx-8" href={logo?.url} target="_blank" rel="noreferrer" key={key}>
                      <img
                        src={logo?.logo?.asset?._ref ? urlFor(logo?.logo?.asset._ref).url() : ""}
                        alt={logo?.name}
                        className="h-16 mobile:h-12 w-auto object-contain max-w-none grayscale"
                        style={{ opacity: 0.5, mixBlendMode: "luminosity" }}
                      />
                    </a>
                  ))}
                </HorizontalTicker>
              </div>
            </div>
          )}
          {/* Hero 2 */}
          <div className="w-full mt-28 pb-24 md:pb-0">
            <div className="w-full h-2/6 px-10 py-14 flex flex-col justify-center md:px-40 md:pb-32">
              <p id="hero2Title" className="text-sm opacity-0 font-bold uppercase leading-none mb-4 md:text-xl">
                {"// WHO WE ARE"}
              </p>
              <p id="hero2Body" className="text-3xl pr-12 opacity-0 md:text-5xl md:pr-0">
                Crafting digital symphonies that <span className="font-bold text-primary">resonate </span>, we're not
                just in the marketing game,{" "}
                <span className="relative">
                  we're redefining it. <div id="pms-underline" className="absolute h-[3px] bg-black" />
                </span>
              </p>
            </div>
          </div>
          <div id="weThink" className="scale-x-90 md:mt-[100px] -mb-[100px] w-screen md:w-auto">
            <div className="w-full pb-60 bg-primary rounded-3xl p-10 pr-18 relative md:p-20 md:m-6 md:w-[calc(100%-48px)] sm:w-[90%]">
              <p
                id="weThinkTitle"
                className="text-[32px] font-medium uppercase leading-10 font-flexible opacity-0 text-white underline underline-offset-[10px] decoration-[1px] w-fit ml-[-25px] mb-9 md:text-5xl md:mb-24"
              >
                WE THINK, THEREFORE
              </p>
              <div className="md:flex justify-between md:w-3/5">
                <p
                  id="weThinkBody1"
                  className="opacity-0 text-[16px] md:text-2xl text-white font-medium mb-6 md:inline-block md:mr-10"
                >
                  <b>WE ARE...</b>
                  <br />
                  ...an innovative digital marketing agency with a<b> one-of-a kind approach.</b> We love what we do and
                  it shows in every project!
                </p>
                <p
                  id="weThinkBody2"
                  className="opacity-0 text-[16px] md:text-2xl text-white font-medium md:inline-block mt-8"
                >
                  Our mission is to <b>help brands reach their full potential</b> by taking care of all aspects from
                  strategy, planning & implementation across multiple channels.{" "}
                </p>
              </div>
              <div className="absolute -right-[20%] -bottom-10 w-screen sm:w-full h-2/6 md:h-full ">
                <Canvas
                  gl={{
                    toneMapping: ACESFilmicToneMapping,
                    antialias: true,
                    toneMappingExposure: 3,
                  }}
                  camera={{
                    position: [-1.3, 0.7, 2.3],
                    rotation: [-0.342918, -0.436332, -0.523599],
                  }}
                >
                  <Environment map={hdrMap} />
                  <FatStar rot={fatStarRotation} map={hdrMap} />
                </Canvas>
              </div>
              {/* </div> */}
            </div>
          </div>
          <div id="hero3" className="md:w-full sm:w-[90%] items-center relative mt-20 md:p-40 md:mt-0">
            <div className="md:w-full sm:w-[90%] md:pl-8 md:pr-5 pb-14 md:p-0 sm:pl-2">
              <p id="hero3Title" className="text-sm font-bold uppercase leading-none mb-4 md:text-xl opacity-0">
                {"// WHAT WE DO"}
              </p>
              <p id="hero3Body" className="text-primary text-2xl font-bold md:text-5xl md:w-4/5 opacity-0 ">
                We are a full-service premium digital marketing agency, dedicated to building long-lasting relationships
                with our clients.
              </p>
            </div>
            <div
              id="hero3Body2"
              className="w-[65vw] mt-18 ml-[35vw] sm:ml-[50vw] md:mt-20 md:ml-[60vw] md:w-1/3 opacity-0"
            >
              <div className="h-0 w-6 mb-2 border-primary border-2"></div>
              <p>
                Our team is committed to providing
                <b> the highest level of service and support,</b> ensuring that you have the resources you need to
                achieve your goals.
              </p>
            </div>
            <div
              id="defyOdds"
              className="absolute w-[170vw] top-[25vh] md:top-[550px] max-w-none md:w-[90vw] md:left-[unset] md:h-[200vh]"
            >
              <h2 className="h-[425vh] will-change-scroll will-change-transform font-flexible text-9xl md:text-[500px] text-primary font-medium uppercase leading-[479.95px] tracking-[5px] whitespace-nowrap select-none">
                DEFY THE ODDS DEFY THE ODDS DEFY THE ODDS DEFY THE ODDS DEFY THE ODDS
              </h2>
            </div>
            <div className="relative w-full h-[550px]">
              <div className="absolute left-0 w-screen h-screen">
                <Canvas
                  gl={{
                    toneMapping: ACESFilmicToneMapping,
                    antialias: true,
                    toneMappingExposure: 1.5,
                  }}
                  camera={{
                    position: [5, 0, window.screen.width < 768 ? 50 : 5],
                  }}
                >
                  <Environment map={hdrMap} />
                  <SlimStar rot={slimStarRotation} pos={slimStarPosition} map={hdrMap} />
                </Canvas>
              </div>
            </div>
            <div className="w-full -mt-[30vh] md:mt-[30vh] pl-8 pr-8 py-14 flex flex-col justify-center md:px-0">
              <p id="ourPromise" className="text-sm font-bold uppercase leading-none mb-4 md:text-xl opacity-0 -ml-4">
                {"// OUR PROMISE"}
              </p>
              {window.screen.width > 768 ? (
                <span className={`text-2xl md:text-4xl leading-10 md:leading-[4rem] pr-8 pb-52 md:pb-0`}>
                  We prioritize{" "}
                  <span className="whitespace-nowrap">
                    delivering{" "}
                    <PillText title="pill1" order={1} width={pill1Width}>
                      measurable outcomes
                    </PillText>
                  </span>{" "}
                  for our clients. With a deep understanding of their objectives, we employ{" "}
                  <span className="whitespace-nowrap">
                    a{" "}
                    <PillText title="pill2" order={2} width={pill2Width}>
                      comprehensive strategy
                    </PillText>
                  </span>{" "}
                  on each campaign,{" "}
                  <span className="whitespace-nowrap">
                    ensuring{" "}
                    <PillText title="pill3" order={3} width={pill3Width}>
                      optimal conversions.
                    </PillText>
                  </span>{" "}
                </span>
              ) : (
                <span className={`text-lg md:text-4xl leading-10 md:leading-[4rem] pr-8 pb-52 md:pb-0`}>
                  We prioritize delivering
                  <span className="whitespace-nowrap">
                    {" "}
                    <PillText title="pill1" order={1} width={pill1Width}>
                      measurable outcomes
                    </PillText>
                  </span>{" "}
                  for our clients. With a deep understanding of their objectives, we employ{" "}
                  <span className="whitespace-nowrap">
                    a{" "}
                    <PillText title="pill2" order={2} width={pill2Width}>
                      comprehensive strategy
                    </PillText>
                  </span>{" "}
                  on each campaign,{" "}
                  <span className="whitespace-nowrap">
                    ensuring{" "}
                    <PillText title="pill3" order={3} width={pill3Width}>
                      optimal conversions.
                    </PillText>
                  </span>{" "}
                </span>
              )}
            </div>
            <div className="absolute right-0 -bottom-72 md:right-28 z-[100]">
              <div className="w-screen md:w-[40vw]  h-[100vh]">
                <Canvas
                  gl={{
                    toneMapping: ACESFilmicToneMapping,
                    antialias: true,
                    toneMappingExposure: 3,
                  }}
                  camera={{
                    position: [0, 0, 10],
                  }}
                >
                  <Environment map={hdrMap} />
                  <Arrow rot={arrowRotation} pos={arrowPosition} map={hdrMap} />
                </Canvas>
              </div>
            </div>
          </div>
          <div id="hero4" className=" scale-x-90 mt-[100px] -mb-[100px]">
            <div
              id="servicesTarget"
              className="w-full min-h-screen md:flex flex-col items-start pt-24 bg-primary rounded-2xl px-8 md:flex-row md:p-32 md:m-6 md:w-[calc(100%-48px)] md:min-h-fit"
            >
              <div className="md:w-1/2 md:pb-44" id="hero4Title">
                <h3 className="font-flexible text-white text-8xl leading-[5rem] mb-6 md:text-[200px] md:leading-[10rem] md:mb-14">
                  OUR
                  <br />
                  SERVICES
                </h3>
                <PillButton
                  className="w-48 mb-20 cursor-pointer md:w-96 md:text-3xl md:px-7 md:py-4"
                  textColor={VarColor.white}
                  strokeColor={VarColorVal.white}
                  arrowLength={ArrowIconLength.small}
                  arrowSize={16}
                  onClick={() => {
                    // setFormOpen(!formOpen)
                    const a = document.createElement('a');
                    a.href = 'mailto:business@odsmarketing.com';
                    a.click();
                  }}
                >
                  REACH OUT
                </PillButton>
              </div>
              <div className="grow-0 mb-24 sm:mb-0 h-full overflow-y-auto relative no-scrollbar md:w-1/2">
                {services.map((service: Service, i: number) => {
                  return (
                    <div key={i} className="hero4Bullet opacity-0">
                      <div className="flex mb-4">
                        <img
                          src={`assets/icons/${service.icon}.svg`}
                          alt={service.icon}
                          className="mr-3 w-[32px] h-[32px]"
                        />
                        <p className="text-white text-2xl inline-block">{service.heading}</p>
                      </div>
                      <p className="text-white">{service.paragraph}</p>
                      <div className="w-full h-0 border-t-[1px] border-white my-8"></div>
                    </div>
                  );
                })}
                <div
                  className="sticky bottom-0 w-full h-3/4 bg-gradient-to-t from-primary via- z-20 pointer-events-none scrollbar-none md:hidden"
                  style={{
                    ["marginTop" as any]: "calc(-100% + 240px)",
                  }}
                ></div>
              </div>
            </div>
          </div>
          {/* <div className="w-full pl-8 pr-7 md:flex md:min-h-screen justify-between items-center md:px-36">
            <div className="min-h-[400px] mb-[50vw] md:mb-0 md:w-1/2">
              <p id="tpTitle" className="font-bold mb-4 md:text-xl opacity-0">
                {"// TRUSTPILOT"}
              </p>
              <h3
                id="tpSubtitle"
                className="text-primary text-4xl font-bold leading-[48px] mb-8 md:text-8xl opacity-0 -mr-5"
              >
                Trusted by{" "}
                <span className="relative">
                  1,295
                  <div id="tp-underline" className="absolute h-[3px] bg-primary right-0" />
                </span>{" "}
                companies and counting.
              </h3>
            </div>
            <div className="min-h-[320px] flex justify-center align-center md:w-1/2 opacity-0" id="tp-box">
              <TrustBox />
            </div>
          </div> */}
          {/* <div className="overflow-hidden w-screen">
            <div className="min-h-[100px] w-[120vw] " id="brands-band">
              <HorizontalTicker duration={30000}>
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((_, key) => (
                  <div className="w-[12vw] mx-[1vw]">
                    <img src="assets/images/brand-dummy.svg" alt="brand" className="w-full self-center " />
                  </div>
                ))}
              </HorizontalTicker>
            </div>
          </div> */}

          <div className="w-full pl-8 pr-7 md:flex md:min-h-screen justify-between items-end md:px-36 sm:-mt-[30vh]">
            <div className="min-h-[320px] flex justify-center align-center md:w-1/2 opacity-0  h-full" id="hero5Img">
              <img
                src="assets/images/tiktok_marketing_partner.png"
                alt="Tiktok Marketing Partner"
                className="w-2/3 self-center "
              />
            </div>
            <div className="min-h-[400px] mb-[50vw] sm:mb-[-10vh] md:w-1/2">
              <p id="hero5Title" className="font-bold mb-4 md:text-xl opacity-0">
                {"// BETTER MARKETING"}
              </p>
              <h3
                id="hero5Subtitle"
                className="text-primary text-5xl font-bold leading-[48px] mb-8 md:text-8xl opacity-0 -mr-5"
              >
                We are TikTok Marketing Partners!
              </h3>
              <p id="hero5Body" className="md:text-3xl opacity-0 -mr-5">
                As a TikTok Marketing Partner, ODS Marketing offers a range of benefits for its clients advertising on
                the platform such as access to exclusive features, tools, data insights, and so much more!
              </p>
            </div>
          </div>
          <div
            className="hidden w-full h-[425vh] 2xl:h-[400vh] relative md:block pt-[calc((100vh-700px)/2)]"
            id="hero6"
          >
            <div data-scroll data-scroll-sticky data-scroll-target="#hero6" data-scroll-offset="0%,50%">
              <h2
                className="absolute will-change-scroll will-change-transform h-[425vh] 2xl:h-[400vh] top-0 left-[650vw] 2xl:left-[500vw] font-flexible text-[500px] text-primary font-medium uppercase leading-[479.95px] tracking-[5px] whitespace-nowrap"
                data-scroll
                data-scroll-speed="13"
                data-scroll-position="top"
                data-scroll-direction="horizontal"
              >
                GET READY TO ELEVATE YOUR BUSINESS
              </h2>
            </div>
          </div>
          <div className="hidden md:block h-[55vh]" />
          <div
            data-scroll
            data-scroll-id="bubble"
            className="relative w-full z-50 md:z-0"
            style={{
              ["padding-top" as any]: `${(window.innerWidth > 640 ? 1.4 : 3) * (bubbleWidth / 4)}vw`,
              ["height" as any]: `${1.4 * (1 / bubbleWidth / 2)}vw`,
            }}
          >
            <div
              className="absolute rounded-t-full bg-primary overflow-hidden -mt"
              style={{
                ["left" as any]: `${50 - bubbleWidth / 2}vw`,
                ["bottom" as any]: `-${(bubbleWidth / 2) * 1}vw`,
                ["width" as any]: `${bubbleWidth}vw`,
                ["height" as any]: `${bubbleWidth * 1}vw`,
              }}
            >
              <div
                className="absolute w-screen"
                style={{
                  ["left" as any]: `${bubbleWidth / 2 - 50}vw`,
                  ["top" as any]: `${bubbleWidth / (window.screen.width < 768 ? 3 : 2) - 50}vw`,
                }}
              >
                <h3 className="font-flexible text-white text-8xl font-medium uppercase mx-auto mt-[25vw] w-fit md:text-[300px] md:mt-[10vw]">
                  REACH OUT
                </h3>
                <p className="mt-8 relative text-white text-center mx-6 mb-12 md:font-3xl md:w-1/4 md:mx-auto">
                  The digital marketing landscape is changing rapidly and our team of experts at ODS Marketing has the
                  knowledge to keep you ahead.
                </p>
                <div className="flex justify-between flex-wrap mb-20 mx-6 md:w-3/5 md:mx-auto md:mb-10">
                  {/* <PillButton
                    className="w-[49%] text-xl mb-3 z-10 md:w-[24%]"
                    textColor={VarColor.white}
                    strokeColor={VarColorVal.white}
                    arrowLength={ArrowIconLength.small}
                    arrowSize={16}
                  >
                    WHATSAPP
                  </PillButton> */}
                  <div />
                  <PillButton
                    className="hidden w-full text-xl mb-3 z-10 md:flex md:w-[49%]"
                    textColor={VarColor.white}
                    strokeColor={VarColorVal.white}
                    arrowLength={ArrowIconLength.small}
                    arrowSize={16}
                    to="mailto:business@odsmarketing.com"
                  >
                    BUSINESS@ODSMARKETING.COM
                  </PillButton>
                  <div />
                  {/* <PillButton
                    className="w-[49%] text-xl mb-3 z-10 md:w-[24%]"
                    textColor={VarColor.white}
                    strokeColor={VarColorVal.white}
                    arrowLength={ArrowIconLength.small}
                    arrowSize={16}
                  >
                    TELEGRAM
                  </PillButton> */}
                  <PillButton
                    className="w-full text-xl z-10 md:hidden"
                    textColor={window.screen.width < 640 ? VarColor.primary : VarColor.white}
                    strokeColor={VarColorVal.white}
                    bgColor={window.screen.width < 640 ? VarColorVal.white : VarColorVal.primary}
                    arrowLength={ArrowIconLength.small}
                    arrowSize={16}
                    to="mailto:business@odsmarketing.com"
                  >
                    BUSINESS@ODSMARKETING.COM
                  </PillButton>
                </div>
              </div>
            </div>
          </div>
          <div
            //   data-scroll-id="bubble"
            className={`${
              (bubbleWidth > 99 || window.innerWidth <= 640) && "bg-primary"
            } w-full px-8 relative z-10 md:min-h-[50vh] top-[3vw]`}
          >
            <div className="pt-60 relative z-10 min-h-[120vh] md:pt-0 md:min-h-[600px]">
              <div className="flex justify-between items-center mb-9 h-5">
                <img src="assets/images/we_defy_the_odds_white.svg" alt="We defy the odds" className="h-1/2" />
                <p className="text-sm text-white">EST. 2020</p>
              </div>
              <div className="flex px-2 py-12 border-t-[1px] border-white relative md:flex md:flex-col">
                <LogoIcon className="top-28 right-0 md:top-20 md:left-16 w-20 h-20" stroke={VarColorVal.white} />
                <div className="md:flex w-full justify-end md:mt-10 md:mb-10">
                  <div className="mb-12 mt-8 sm:mt-0 flex flex-col text-end">
                    <h5 className="text-xl font-medium text-white md:text-3xl text-end mobile:hidden">Contact us</h5>
                    <a
                      href="https://www.linkedin.com/company/ods-marketing-ab/"
                      target="_blank"
                      rel="noreferrer"
                      className="text-sm text-white mt-6 md:text-base md:hover:text-[#FFF] cursor-pointer"
                    >
                      LINKEDIN
                    </a>

                    <a
                      href="tel:+46708203182"
                      target="_blank"
                      rel="noreferrer"
                      className="text-sm text-white mt-6 md:text-base md:hover:text-[#FFF] cursor-pointer"
                    >
                      +46 708 203 182
                    </a>
                    <a
                      href="mailto:business@odsmarketing.com"
                      className="text-sm text-white mt-6 md:text-base md:hover:text-[#FFF] cursor-pointer"
                    >
                      BUSINESS@ODSMARKETING.COM
                    </a>
                  </div>
                  {/* <div className="mb-6">
                    <h5 className="text-xl font-medium text-white md:text-3xl">Office Address</h5>
                    <p className="text-sm text-white mt-6 md:text-base">42421 Emilie Ferry, Apt. 926, 55032</p>
                    <p className="text-sm text-white mt-6 md:text-base">New Dax, Nebraska</p>
                    <p className="text-sm text-white mt-6 md:text-base">United States</p>
                  </div> */}
                </div>
                <div className="hidden h-5 w-full md:flex items-center justify-between pl-16 z-10">
                  <img
                    src="assets/images/we_defy_the_odds_white.svg"
                    alt="We defy the odds"
                    className="h-[10px] mr-9 mt-16 sm:mt-0"
                  />
                  <div className="border-t-[1px] border-white grow"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden md:block h-[60vh]"></div>
      </main>
    </>
  );
}

export default Home;
