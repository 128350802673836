import { ArrowIconLength, ArrowIconProps } from "../lib/props";

const ArrowIcon = (props: ArrowIconProps) => {
  const miniArrow = (
    <svg
      className={props.className}
      width={props.size * 0.8}
      height={props.size * 0.8}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="arrow-right-363-svgrepo-com 2" clipPath="url(#clip0_471_1283)">
        <g id="Page-1">
          <g id="Dribbble-Light-Preview">
            <g id="icons">
              <path
                id="arrow_right-[#363]"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.67661 0.175644C6.44221 -0.058548 6.06273 -0.058548 5.82892 0.175644C5.59452 0.409842 5.59452 0.788726 5.82892 1.02232L9.68968 4.88512C9.87853 5.07379 9.74485 5.40074 9.47807 5.40074H0.609686C0.278763 5.40074 0 5.66455 0 5.99517V5.99752C0 6.32815 0.278763 6.59869 0.609686 6.59869H9.47807C9.74485 6.59869 9.87853 6.91685 9.68968 7.10553L5.81034 10.9797C5.57594 11.2133 5.57594 11.5911 5.81034 11.8253C6.04414 12.0589 6.42362 12.0583 6.65803 11.8241L11.6488 6.83775C12.117 6.36996 12.117 5.61101 11.6488 5.14322C11.5403 5.03541 6.5681 0.067236 6.67661 0.175644Z"
                fill={props.stroke}
              />
            </g>
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_471_1283">
          <rect width="12" height="12" fill={props.stroke} />
        </clipPath>
      </defs>
    </svg>
  );

  const smallArrow = (
    <svg
      className={props.className}
      width={props.size}
      height={props.size}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="arrow-right-363-svgrepo-com 2" clipPath="url(#clip0_471_1283)">
        <g id="Page-1">
          <g id="Dribbble-Light-Preview">
            <g id="icons">
              <path
                id="arrow_right-[#363]"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.67661 0.175644C6.44221 -0.058548 6.06273 -0.058548 5.82892 0.175644C5.59452 0.409842 5.59452 0.788726 5.82892 1.02232L9.68968 4.88512C9.87853 5.07379 9.74485 5.40074 9.47807 5.40074H0.609686C0.278763 5.40074 0 5.66455 0 5.99517V5.99752C0 6.32815 0.278763 6.59869 0.609686 6.59869H9.47807C9.74485 6.59869 9.87853 6.91685 9.68968 7.10553L5.81034 10.9797C5.57594 11.2133 5.57594 11.5911 5.81034 11.8253C6.04414 12.0589 6.42362 12.0583 6.65803 11.8241L11.6488 6.83775C12.117 6.36996 12.117 5.61101 11.6488 5.14322C11.5403 5.03541 6.5681 0.067236 6.67661 0.175644Z"
                fill={props.stroke}
              />
            </g>
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_471_1283">
          <rect width="12" height="12" fill={props.stroke} />
        </clipPath>
      </defs>
    </svg>
  );

  const bigArrow = (
    <svg
      className={props.className}
      width={props.size * 3.2}
      height={props.size}
      viewBox="0 0 77 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Dribbble-Light-Preview">
        <g id="icons">
          <path
            id="arrow_right-[#363]"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M66.3842 0.351288C65.9168 -0.117096 65.1601 -0.117096 64.6938 0.351288C64.2264 0.819684 64.2264 1.57745 64.6938 2.04465L72.3928 9.77023C72.7694 10.1476 72.5028 10.8015 71.9708 10.8015H1.21582C0.555904 10.8015 0 11.3291 0 11.9903V11.995C0 12.6563 0.555904 13.1974 1.21582 13.1974H71.9708C72.5028 13.1974 72.7694 13.8337 72.3928 14.2111L64.6568 21.9595C64.1893 22.4267 64.1893 23.1821 64.6568 23.6505C65.123 24.1177 65.8797 24.1166 66.3472 23.6482L76.2997 13.6755C77.2334 12.7399 77.2334 11.222 76.2997 10.2864C76.0833 10.0708 66.1678 0.134472 66.3842 0.351288Z"
            fill={props.stroke}
          />
        </g>
      </g>
    </svg>
  );

  switch (props.arrowLength) {
    case ArrowIconLength.xs:
      return miniArrow;
    case ArrowIconLength.small:
      return smallArrow;
    case ArrowIconLength.large:
      return bigArrow;
  }
};

export default ArrowIcon;
