import { useState, useEffect, FC } from 'react';
import LogoIcon from '../components/logoIcon';
import { VarColorVal } from '../lib/vars';
import clsx from 'clsx';

interface LoadingScreenProps {
	hasLoaded: boolean;
}

const LoadingScreen: FC<LoadingScreenProps> = ({ hasLoaded }) => {
	const [fadeOut, setFadeOut] = useState(false);
	const [doneFadeout, setDoneFadeout] = useState(false);

	useEffect(() => {
		if (hasLoaded) {
			// Start the fade-out animation
			setFadeOut(true);

			// After the fade-out animation duration (adjust as needed),
			// remove the component by setting fadeOut to false
			const fadeOutTimeout = setTimeout(() => {
				setDoneFadeout(true);
			}, 1000); // 1000ms = 1 second (adjust as needed)

			// Clean up the timeout
			return () => clearTimeout(fadeOutTimeout);
		}
	}, [hasLoaded]);

	return (
    <>
      {!doneFadeout && (
				<div className={clsx('absolute top-0 w-screen h-screen bg-white !z-[99999] flex items-center justify-center', 
        fadeOut ? 'animate-fade-out' : ''
        )}>
					<div
						className={clsx(
							'flex flex-col items-center justify-center',
							fadeOut ? 'animate-fade-out' : ''
						)}
					>
						<LogoIcon
							stroke={VarColorVal.primary}
							className={clsx(
								'w-24 h-24 md:block cursor-pointer',
								fadeOut ? 'animate-fade-out' : ''
							)}
						/>
						{/* Loading bar */}
						<div
							className={clsx('w-28 h-1 bg-gray-300 mt-5' ,
              fadeOut ? 'animate-fade-out' : ''
            )}
						>
							<div className='h-full bg-primary rounded-full animate-loading-bar' />
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default LoadingScreen;
