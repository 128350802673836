import { FC, ReactNode, createContext, useContext, useState } from "react";

interface NavContext {
  scrollToId: any;
  setScrollToId: any;
}

const NavContext = createContext<NavContext>(undefined!);

interface NavContextProviderProps {
  children: ReactNode;
}

export const NavContextProvider: FC<NavContextProviderProps> = ({ children }) => {
  const [scrollToId, setScrollToId] = useState("");

  return <NavContext.Provider value={{ scrollToId, setScrollToId }}>{children}</NavContext.Provider>;
};

export function useNavContext() {
  return useContext(NavContext);
}
