import React from "react";
import { IconProps } from "../lib/props";

interface MenuIconProps extends IconProps {
  active: boolean;
}

const MenuIcon = (props: MenuIconProps) => {
  return props.active ? (
    <svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 32">
        <path
          id="Line 15"
          d="M1 13H19"
          stroke={props.stroke}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          id="Line 16"
          d="M1 7H13"
          stroke={props.stroke}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          id="Line 17"
          d="M1 1H17.5"
          stroke={props.stroke}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
    </svg>
  ) : (
    <svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <g id="Group 32">
        <path
          id="Line 15"
          d="M19 13H1"
          stroke={props.stroke}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          id="Line 16"
          d="M19 7H7"
          stroke={props.stroke}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          id="Line 17"
          d="M19 1H2.5"
          stroke={props.stroke}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
};

export default MenuIcon;
