import React from "react";
import { IconProps } from "../lib/props";

const LogoIcon = (props: IconProps) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <g id="Frame 2">
        <g id="Group 1">
          <path
            id="Vector"
            d="M17.6956 33.9663C17.6956 36.1955 17.738 38.4247 17.6815 40.6539C17.6392 42.3329 16.2847 43.4898 14.5211 43.4757C12.9127 43.4616 11.4736 42.1777 11.4595 40.5834C11.4172 36.1673 11.4172 31.7512 11.4595 27.3351C11.4736 25.6843 12.8986 24.3863 14.5211 24.3722C16.2706 24.3581 17.6251 25.5291 17.6674 27.2786C17.7521 29.5078 17.6956 31.7371 17.6956 33.9663Z"
            fill={props.stroke}
          />
          <path
            id="Vector_2"
            d="M21.125 24.2594C21.125 21.4799 21.125 18.7146 21.125 15.9351C21.125 14.7358 21.2097 14.6653 22.4089 14.6512C24.4406 14.623 26.049 15.3425 26.952 17.219C27.2342 17.8116 27.3894 18.5311 27.4035 19.1802C27.4458 22.5381 27.4317 25.9101 27.4176 29.268C27.4035 31.8641 25.4988 33.7829 22.8886 33.8534C21.125 33.8958 21.125 33.8958 21.125 32.1463C21.125 29.5361 21.125 26.8977 21.125 24.2594Z"
            fill={props.stroke}
          />
          <path
            id="Vector_3"
            d="M30.7754 10.7994C30.7754 9.92468 30.7754 9.06403 30.7754 8.18928C30.7895 6.41155 32.0875 5.08531 33.8794 5.04299C34.6836 5.02888 35.4737 5.0571 36.2779 5.02888C36.814 5.01477 37.0398 5.26873 37.0398 5.79076C37.0256 7.41329 37.068 9.03581 37.0115 10.6583C36.9269 12.6618 34.9234 14.4678 32.7647 14.5101C30.6766 14.5524 30.7613 14.6794 30.7895 12.6054C30.7754 12.0269 30.7754 11.4061 30.7754 10.7994Z"
            fill={props.stroke}
          />
          <path
            id="Vector_4"
            d="M36.8266 21.0425C36.7138 21.4093 36.5021 22.0866 35.9378 22.7356C35.1618 23.6244 34.0048 24.1324 32.7068 24.1606C30.4494 24.2029 30.7739 24.2875 30.7739 22.2559C30.7739 20.972 30.6892 19.6598 30.8868 18.39C31.2536 16.1185 33.0454 14.7076 35.3311 14.6653C36.1776 14.6511 36.6009 14.637 36.8125 14.8487C37.0241 15.0462 37.0241 15.3425 37.0241 16.3442C37.0241 17.7833 37.0241 17.9667 37.0241 17.9667C36.9395 20.5346 36.9395 20.6757 36.8266 21.0425Z"
            fill={props.stroke}
          />
        </g>
      </g>
    </svg>
  );
};

export default LogoIcon;
