export enum VarColor {
    primary = "primary",
    white = "white",
    black = "black",
}

export enum VarColorVal {
    primary = "#5700FF",
    // white: "#FBCEAD",
    white = "#EEEEEE",
    black = "#080808",
}
