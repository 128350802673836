import { PillButtonProps } from "../lib/props";
import ArrowIcon from "./arrowIcon";
import { VarColor, VarColorVal } from "../lib/vars";
import clsx from "clsx";

const PillButton = (props: PillButtonProps) => {
  return (
    <div
      onClick={props.onClick}
      className={clsx(
        props.className,
        `group rounded-full px-4 py-2 flex justify-between items-center transition border border-${props.textColor} text-${props.textColor}`,
        props.textColor === VarColor.primary ? "hover:bg-primary" : "hover:bg-white",
        props.textColor === VarColor.primary ? "hover:text-white" : "hover:text-primary",
        "mobile:bg-white mobile:hover:text-primary mobile:hover:bg-white mobile:text-primary"
      )}
    >
      <a
        href={props.to}
        target="_blank"
        rel="noreferrer"
        className={`select-none flex justify-between items-center w-full`}
      >
        {props.children}
        <ArrowIcon
          className="group-hover:hidden z-[999] hidden mobile:block"
          arrowLength={props.arrowLength}
          stroke={VarColorVal.primary}
          size={props.arrowSize}
        />
        <ArrowIcon
          className="group-hover:hidden z-[999] mobile:hidden"
          arrowLength={props.arrowLength}
          stroke={props.strokeColor}
          size={props.arrowSize}
        />
        <ArrowIcon
          className="hidden group-hover:block z-[999] mobile:!stroke-primary"
          arrowLength={props.arrowLength}
          stroke={props.strokeColor === VarColorVal.primary ? VarColorVal.white : VarColorVal.primary}
          size={props.arrowSize}
        />
      </a>
    </div>
  );
};

export default PillButton;
