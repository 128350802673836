import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Home from './pages/Home';
import { Suspense, useEffect, useState } from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './lib/clients/query.client';
import { NavContextProvider } from './context/navContext';
import OurWork from './pages/OurWork';
import LoadingScreen from './pages/Loading';

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <Suspense>
        <Home />
      </Suspense>
    ),
  },
  {
    path: '/our-work',
    element: (
      <Suspense>
        <OurWork />
      </Suspense>
    ),
  },
]);

const App = () => {
	const [hasLoaded, setHasLoaded] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			setHasLoaded(true);
		}, 2000);
	}, []);

	return (
		<NavContextProvider>
			<QueryClientProvider client={queryClient}>
          <LoadingScreen hasLoaded={hasLoaded} />             
					<RouterProvider router={router} />
			</QueryClientProvider>
		</NavContextProvider>
	);
};

export default App;
