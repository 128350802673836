import clsx from "clsx";
import { FC } from "react";

interface ProductTileProps {
  colSpan: number;
  title: string;
  type?: string;
  body?: string;
  image?: string;
  url?: string;
  index: number;
}

const ProductTile: FC<ProductTileProps> = ({ colSpan, title, type, body, image, url, index }) => {
  return (
    <a
      href={url}
      target="_blank"
      rel="noreferrer"
      className={clsx(
        "hover:bg-primary mobile:bg-primary",
        `col-span-${colSpan} mobile:!col-span-1`,
        "flex items-center mobile:items-start",
        `border-2 border-primary rounded-[20px] transition-all relative group overflow-hidden cursor-pointer col-span min-w-full`,
        `animate-fade-in min-h-[400px] mobile:min-h-[380px]`
      )}
      style={{
        gridColumn: `span ${colSpan}`,
        animationDelay: `${index * 200 + 200}ms`,
      }}
    >
      <div
        className={clsx(
          "text-primary group-hover:text-white mobile:text-white",
          "ml-[5%] transition-all w-full z-10 mobile:mt-5"
        )}
      >
        <p className="text-3xl font-medium">{title} </p>
        {type && <p className="text-regular uppercase">{type}</p>}
        {body && (
          <p
            className={clsx(
              "hidden group-hover:inline-block mobile:inline-block",
              "w-[25%] mobile:w-[80%]",
              "mt-2 mobile:mt-1",
              "text-regular transition-all text-white"
            )}
          >
            {body}
          </p>
        )}
      </div>
      {image && (
        <div
          className={clsx(
            "opacity-0 group-hover:opacity-100 mobile:opacity-100",
            "absolute h-[90%] bottom-0 right-0 transition-all flex justify-end items-end"
          )}
        >
          <img
            src={image}
            alt={title}
            className={clsx(
              colSpan === 2
                ? "w-full mobile:w-[90%] laptop:w-[60%] h-full object-right-bottom object-contain select-none"
                : "w-[60%] laptop:w-[50%] h-[90%] mobile:h-[78%]  object-left-bottom object-cover select-none"
            )}
          />
        </div>
      )}
    </a>
  );
};

export default ProductTile;
