import { useEffect, useMemo, useRef, useState } from "react";
import locomotiveScroll from "locomotive-scroll";
import ScrollTrigger from "gsap/ScrollTrigger";
import gsap from "gsap";
import { VarColor, VarColorVal } from "../lib/vars";
import Navbar from "../components/navbar";
import ProductTile from "../components/productTile";
import LogoIcon from "../components/logoIcon";
import PillButton from "../components/pillButton";
import { ArrowIconLength } from "../lib/props";
import { useQuery } from "@tanstack/react-query";
import { sanityClient, urlFor } from "../lib/clients/sanity.client";

interface Project {
  title: string;
  category?: string;
  description?: string;
  url?: string;
  image: {
    asset: {
      _ref: string;
    };
  };
}

const OurWork = () => {
  const { data } = useQuery(["allWork"], () => sanityClient.fetch(`*[_type == "projects"]|order(orderRank)`));

  const projectPairs: Project[][] = useMemo(() => {
    if (!data) return [];

    return data.reduce((result, value, index, array) => {
      if (index % 2 === 0) result.push(array.slice(index, index + 2));
      return result;
    }, []);
  }, [data]);

  const scrollElRef = useRef(null);
  const scrollRef = useRef<LocomotiveScroll | null>(null);

  const [bgColor] = useState(VarColorVal.white);
  const [menuActive, setMenuActive] = useState(false);

  const [bubbleWidth, setBubbleWidth] = useState(0);

  const bubblePos = useRef(0);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    if (scrollElRef.current) {
      scrollRef.current = new locomotiveScroll({
        el: scrollElRef.current,
        smooth: true,
        smartphone: {
          smooth: true,
        },
        multiplier: window.screen.width < 768 ? 1.4 : 0.95,
      });

      const handleScroll = (event: locomotiveScroll.OnScrollEvent) => {
        ScrollTrigger.update();

        if (Object.keys(event.currentElements).includes("bubble")) {
          if (bubblePos.current === 0) bubblePos.current = event.scroll.y;
          setBubbleWidth(
            Math.min(Math.max(event.scroll.y, 0) - bubblePos.current, window.screen.width < 768 ? 2000 : 500) /
              (window.screen.width < 768 ? 7 : 5)
          );
        }
      };

      scrollRef.current.on("scroll", handleScroll);

      ScrollTrigger.scrollerProxy(scrollElRef.current, {
        scrollTop(value) {
          return arguments.length
            ? scrollRef.current.scrollTo(value, {
                duration: 0,
                disableLerp: true,
              })
            : // @ts-ignore
              scrollRef.current.scroll.instance.scroll.y;
        },
        getBoundingClientRect() {
          return {
            top: 0,
            left: 0,
            width: window.innerWidth,
            height: window.innerHeight,
          };
        },
        pinType: scrollElRef.current.style.transform ? "transform" : "fixed",
      });

      ScrollTrigger.addEventListener("refresh", () => scrollRef.current.update());
      ScrollTrigger.defaults({ scroller: scrollElRef.current });

      return () => {
        scrollRef.current?.destroy();
      };
    }
  }, [scrollElRef, data]);

  return (
    <>
      <Navbar scrollRef={scrollRef} bgColor={bgColor} menuActive={menuActive} setMenuActive={setMenuActive} />
      <main
        data-scroll-container
        className="flex flex-col items-start w-screen bg-white overflow-hidden"
        ref={scrollElRef}
      >
        <div className="w-screen pt-8">
          <div className="pl-8 pr-7 md:min-h-screen md:px-36 mt-[10vh] min-w-screen">
            <div className="min-h-[400px] mb-[50vw] md:mb-0 min-w-screen">
              {data &&
                projectPairs.map((pair, rowIndex) => (
                  <div key={rowIndex} className={`grid grid-cols-3 mobile:grid-cols-1 gap-8 mb-8 min-w-screen`}>
                    {pair.map((product, itemIndex) => {
                      let colSpanValue;
                      if (rowIndex % 2 === 0) {
                        // even row
                        colSpanValue = itemIndex === 0 ? 2 : 1;
                      } else {
                        // odd row
                        colSpanValue = itemIndex === 0 ? 1 : 2;
                      }

                      return (
                        <ProductTile
                          key={`${rowIndex}-${itemIndex}`}
                          colSpan={colSpanValue}
                          title={product.title}
                          type={product.category}
                          body={product.description}
                          image={product?.image?.asset?._ref ? urlFor(product.image.asset._ref).url() : ""}
                          url={product.url}
                          index={2 * rowIndex + itemIndex}
                        />
                      );
                    })}
                  </div>
                ))}
            </div>
          </div>

          <div className="hidden md:block h-[45vh] mobile:hidden"></div>
          <div
            data-scroll
            data-scroll-id="bubble"
            className="relative w-full z-50 md:z-0"
            style={{
              ["paddingTop" as any]: `${(window.innerWidth > 640 ? 1.4 : 3) * (bubbleWidth / 4)}vw`,
              ["height" as any]: `${1.4 * (1 / bubbleWidth / 2)}vw`,
            }}
          >
            <div
              className="absolute rounded-t-full bg-primary overflow-hidden"
              style={{
                ["left" as any]: `${50 - bubbleWidth / 2}vw`,
                ["bottom" as any]: `-${(bubbleWidth / 2) * (window.innerWidth > 640 ? 1 : 1.4)}vw`,
                ["width" as any]: `${bubbleWidth}vw`,
                ["height" as any]: `${bubbleWidth * (window.innerWidth > 640 ? 1 : 1)}vw`,
              }}
            >
              <div
                className="absolute w-screen"
                style={{
                  ["left" as any]: `${bubbleWidth / 2 - 50}vw`,
                  ["top" as any]: `${bubbleWidth / (window.screen.width < 768 ? 3 : 2) - 50}vw`,
                }}
              >
                <h3 className="font-flexible text-white text-8xl font-medium uppercase mx-auto mt-[25vw] w-fit md:text-[300px] md:mt-[10vw]">
                  REACH OUT
                </h3>
                <p className="mt-8 relative text-white text-center mx-6 mb-12 md:font-3xl md:w-1/4 md:mx-auto">
                  The digital marketing landscape is changing rapidly and our team of experts at ODS Marketing has the
                  knowledge to keep you ahead.
                </p>
                <div className="flex justify-center flex-wrap mb-20 mx-6 md:w-3/5 md:mx-auto md:mb-10">
                  <PillButton
                    className="hidden w-full text-xl mb-3 z-10 md:flex md:w-[49%]"
                    textColor={VarColor.white}
                    strokeColor={VarColorVal.white}
                    arrowLength={ArrowIconLength.small}
                    arrowSize={16}
                    to="mailto:business@odsmarketing.com"
                  >
                    BUSINESS@ODSMARKETING.COM
                  </PillButton>

                  <PillButton
                    className="w-full text-xl z-10 md:hidden"
                    textColor={VarColor.white}
                    strokeColor={VarColorVal.white}
                    arrowLength={ArrowIconLength.small}
                    arrowSize={16}
                    to="mailto:business@odsmarketing.com"
                  >
                    BUSINESS@ODSMARKETING.COM
                  </PillButton>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`${
              (bubbleWidth > 99 || window.innerWidth <= 640) && "bg-primary"
            } w-full px-8 relative z-10 md:min-h-[50vh]`}
          >
            <div className="pt-60 relative z-10 min-h-[120vh] md:pt-0 md:min-h-[600px]">
              <div className="flex justify-between items-center mb-9 h-5">
                <img src="assets/images/we_defy_the_odds_white.svg" alt="We defy the odds" className="h-1/2" />
                <p className="text-sm text-white">EST. 2020</p>
              </div>
              <div className="flex px-2 py-12 border-t-[1px] border-white relative md:flex md:flex-col">
                <LogoIcon className="top-28 right-0 md:top-20 md:left-16 w-20 h-20" stroke={VarColorVal.white} />
                <div className="md:flex w-full justify-end md:mt-10 md:mb-10">
                  <div className="mb-12 mt-8 sm:mt-0 flex flex-col text-end">
                    <h5 className="text-xl font-medium text-white md:text-3xl text-end mobile:hidden">Contact us</h5>
                    <a
                      href="https://www.linkedin.com/company/ods-marketing-ab/"
                      target="_blank"
                      rel="noreferrer"
                      className="text-sm text-white mt-6 md:text-base md:hover:text-[#FFF] cursor-pointer"
                    >
                      LINKEDIN
                    </a>

                    <a
                      href="tel:+46708203182"
                      target="_blank"
                      rel="noreferrer"
                      className="text-sm text-white mt-6 md:text-base md:hover:text-[#FFF] cursor-pointer"
                    >
                      +46 708 203 182
                    </a>
                    <a
                      href="mailto:business@odsmarketing.com"
                      className="text-sm text-white mt-6 md:text-base md:hover:text-[#FFF] cursor-pointer"
                    >
                      BUSINESS@ODSMARKETING.COM
                    </a>
                  </div>
                  {/* <div className="mb-6">
                    <h5 className="text-xl font-medium text-white md:text-3xl">Office Address</h5>
                    <p className="text-sm text-white mt-6 md:text-base">42421 Emilie Ferry, Apt. 926, 55032</p>
                    <p className="text-sm text-white mt-6 md:text-base">New Dax, Nebraska</p>
                    <p className="text-sm text-white mt-6 md:text-base">United States</p>
                  </div> */}
                </div>
                <div className="hidden h-5 w-full md:flex items-center justify-between pl-16 z-10">
                  <img
                    src="assets/images/we_defy_the_odds_white.svg"
                    alt="We defy the odds"
                    className="h-[10px] mr-9 mt-16 sm:mt-0"
                  />
                  <div className="border-t-[1px] border-white grow"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden md:block h-[60vh]"></div>
      </main>
    </>
  );
};

export default OurWork;
